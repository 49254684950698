// @ts-strict-ignore
import React, { useEffect, useState } from "react"

import styled from "styled-components"

import {
  Alert,
  Badge,
  Heading,
  Loading,
  Stack,
  Text,
  TextLink,
} from "@kiwicom/orbit-components"
import {
  NotificationOff as NotificationOffIcon,
  NotificationOn as NotificationOnIcon,
} from "@kiwicom/orbit-components/icons"

import { EmberApiError } from "api/errors"
import { deleteTask, fetchTaskByUid, toggleTaskSubscription } from "api/tasks"

import { ConversationView } from "components/chat"
import {
  EmberCard,
  EmberCardGroup,
  EmberCardSection,
  EmberInfoBox,
  InfoRow,
} from "components/generic/ember-card"
import { EmberConfirmationDialog } from "components/generic/ember-confirmation-dialog"

import { Task, TaskInfo, TaskList, TaskStatus } from "types/task"

import { formatAsDateTime, getDefaultTimezone } from "utils/date-utils"
import { getPersonName } from "utils/name-utils"
import { snakeCaseToTitleCase } from "utils/string-utils"

const DescriptionWrapper = styled.div`
  p {
    margin-bottom: 0;
  }
  > p:first-child {
    margin-top: 0;
  }
`

export const TaskStatusBadge = ({ status }: { status: TaskStatus }) => {
  const badgeType =
    {
      [TaskStatus.TODO]: "warningSubtle", // orange
      [TaskStatus.IN_PROGRESS]: "infoSubtle", // blue
      [TaskStatus.COMPLETE]: "successSubtle", // green
    }[status] ?? "neutral"

  return (
    // @ts-expect-error: yes that string is a valid badge type
    <Badge type={badgeType} border>
      {snakeCaseToTitleCase(status)}
    </Badge>
  )
}

const DeletionDialog = ({
  taskUid,
  onSuccess,
  onError,
  onClose,
}: {
  taskUid: string
  onSuccess: () => void
  onError: (error: EmberApiError) => void
  onClose: () => void
}) => (
  <EmberConfirmationDialog
    title="Delete this task?"
    description={
      <Stack spacing="small">
        <Text>
          Deletion is rarely needed. If the task has been completed, change its
          status to "Complete" rather than deleting it.
        </Text>
        <Text>This cannot be undone.</Text>
      </Stack>
    }
    type="critical"
    primaryText="Delete"
    handlePrimaryClick={() => {
      deleteTask({ taskUid, onSuccess, onError })
    }}
    handleSecondaryClick={onClose}
  />
)

const SubscriptionToggle = ({
  taskUid,
  subscribed,
  setSubscribed,
}: {
  taskUid: string
  subscribed: boolean
  setSubscribed: (subscribed: boolean) => void
}) => {
  const [loading, setLoading] = useState<boolean>(false)

  const toggle = () => {
    //setLoading(true)
    toggleTaskSubscription({
      taskUid,
      subscribe: !subscribed,
      onSuccess: () => {
        setSubscribed(!subscribed)
        //setLoading(false)
      },
      onError: () => {
        // We don't actually display the error. The user will see that the button isn't changing
        setLoading(false)
      },
    })
  }

  if (loading) {
    return <Loading type="inlineLoader" />
  }

  return subscribed ? (
    <TextLink
      onClick={toggle}
      type="primary"
      title="You are receiving notifications for this task. Click to turn off."
    >
      <Stack direction="row">
        On <NotificationOnIcon />
      </Stack>
    </TextLink>
  ) : (
    <TextLink
      onClick={toggle}
      type="secondary"
      title="You are not receiving notifications for this task. Click to turn on."
    >
      <Stack direction="row">
        Off <NotificationOffIcon />
      </Stack>
    </TextLink>
  )
}

interface TaskState {
  task: Task
  editable: boolean
  deletable: boolean
  error: EmberApiError
}

const useTask = (
  setSubscribed: (subscribed: boolean) => void,
  taskUid: string
): TaskState => {
  const [taskInfo, setTaskInfo] = useState<TaskInfo>(null)
  const [error, setError] = useState<EmberApiError>(null)
  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    if ((taskInfo == null || taskInfo.task.uid != taskUid) && !loading) {
      setTaskInfo(null)
      setSubscribed(null)
      setLoading(true)
      const sub = fetchTaskByUid({
        taskUid,
        onSuccess: (taskInfo: TaskInfo) => {
          setLoading(false)
          setTaskInfo(taskInfo)
          setSubscribed(taskInfo.subscribed)
        },
        onError: (error: EmberApiError) => {
          setLoading(false)
          setError(error)
        },
      })
      return () => sub.unsubscribe()
    }
  }, [taskUid])

  return {
    task: taskInfo?.task,
    editable: taskInfo?.editable,
    deletable: taskInfo?.deletable,
    error,
  }
}

interface TaskDetailsProps {
  taskUid: string
  onTaskListClick: (taskList: TaskList) => void
  onTaskEditClick: (task: Task) => void
  onNewTaskClick: (defaultTaskList: TaskList) => void
  onTaskDeleted: (task: Task) => void
}

export const TaskDetails = ({
  taskUid,
  onTaskListClick,
  onTaskEditClick,
  onNewTaskClick,
  onTaskDeleted,
}: TaskDetailsProps) => {
  const [subscribed, setSubscribed] = useState<boolean>(null)
  const [showDeletionDialog, setShowDeletionDialog] = useState<boolean>(false)
  const [deletionError, setDeletionError] = useState<EmberApiError>(null)

  const {
    task,
    editable,
    deletable,
    error: taskLoadingError,
  } = useTask(setSubscribed, taskUid)

  const error = taskLoadingError || deletionError
  if (error) {
    return <Alert type="critical">{error.message}</Alert>
  }
  if (!task) {
    return <Loading text={`Loading task ${taskUid}`} />
  }

  return (
    <Stack spacing="XLarge">
      {showDeletionDialog && (
        <DeletionDialog
          taskUid={taskUid}
          onSuccess={() => onTaskDeleted(task)}
          onError={(error: EmberApiError) => {
            setDeletionError(error)
            setShowDeletionDialog(false)
          }}
          onClose={() => setShowDeletionDialog(false)}
        />
      )}
      <Stack inline wrap shrink>
        <Heading type="title2">
          {snakeCaseToTitleCase(task.type)}: {task.title}
        </Heading>
      </Stack>
      <EmberInfoBox
        sectionTitle="Task Info"
        actions={[
          deletable && (
            <TextLink key="delete" onClick={() => setShowDeletionDialog(true)}>
              Delete Task
            </TextLink>
          ),
          editable && (
            <TextLink key="edit" onClick={() => onTaskEditClick(task)}>
              Edit Task
            </TextLink>
          ),
          <TextLink key="new" onClick={() => onNewTaskClick(task.taskList)}>
            New Task
          </TextLink>,
        ]}
      >
        <InfoRow label="Task List">
          <TextLink onClick={() => onTaskListClick(task.taskList)}>
            {task.taskList ? task.taskList.name : "\u2013"}
          </TextLink>
        </InfoRow>

        <InfoRow label="Status">
          <Stack inline>
            <TaskStatusBadge status={task.status} />
          </Stack>
        </InfoRow>

        <InfoRow label="Assignee">
          {task.assignee ? getPersonName(task.assignee) : "Unassigned"}
        </InfoRow>

        <InfoRow label="Created">
          <Text>
            {formatAsDateTime(task.createdAt, getDefaultTimezone())}
            {" by "}
            {getPersonName(task.createdBy)}
          </Text>
        </InfoRow>

        <InfoRow label="Notifications">
          <Stack grow={false}>
            <SubscriptionToggle
              taskUid={taskUid}
              subscribed={subscribed}
              setSubscribed={setSubscribed}
            />
          </Stack>
        </InfoRow>
      </EmberInfoBox>

      <EmberCardGroup sectionTitle="Description">
        <EmberCard>
          <EmberCardSection>
            <DescriptionWrapper
              dangerouslySetInnerHTML={{
                __html: task.descriptionRendered || "(no description)",
              }}
            />
          </EmberCardSection>
        </EmberCard>
      </EmberCardGroup>

      <ConversationView conversation={task.conversation} />
    </Stack>
  )
}
