import React from "react"

import { AdminLayout } from "components/layout-custom"
import TasksPage from "components/tasks"

const Page = () => (
  <AdminLayout title="Tasks">
    <TasksPage />
  </AdminLayout>
)

export default Page
