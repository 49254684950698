import { EmberApiError, parseError } from "api/errors"

import { Unsubscribable } from "types/observable"
import {
  Task,
  TaskInfo,
  TaskList,
  TaskStatus,
  TaskSummary,
  TaskType,
} from "types/task"

import { fetchFromAPIBase, getQueryString } from "utils/fetch-utils"

interface fetchTaskListsParams {
  onSuccess: (allTaskLists: TaskList[]) => void
  onError: (error: EmberApiError) => void
}

export function fetchTaskLists({
  onSuccess,
  onError,
}: fetchTaskListsParams): Unsubscribable {
  return fetchFromAPIBase({
    path: "/v1/task-lists/",
    method: "GET",
  }).subscribe((response) => {
    if (response && !response.error) {
      onSuccess(response.taskLists)
    } else {
      onError(parseError(response))
    }
  })
}

export interface TaskSelectionQuery {
  // this mirrors `TaskSelectionQuery` in `ember_api/tasks/logic.py`
  type?: TaskType
  status?: TaskStatus
  assigneeUid?: string
  taskListUid?: string
  oldestFirst?: boolean
  limit?: number
}

interface fetchTasksParams {
  query: TaskSelectionQuery
  onSuccess: (allTasks: TaskSummary[]) => void
  onError: (error: EmberApiError) => void
}

export function fetchTasks({
  query = {},
  onSuccess,
  onError,
}: fetchTasksParams): Unsubscribable {
  return fetchFromAPIBase({
    path: "/v1/tasks/" + getQueryString(query),
    method: "GET",
  }).subscribe((response) => {
    if (response && !response.error) {
      onSuccess(response.tasks)
    } else {
      onError(parseError(response))
    }
  })
}

interface fetchTaskByUidParams {
  taskUid: string
  onSuccess: (taskInfo: TaskInfo) => void
  onError: (error: EmberApiError) => void
}

export function fetchTaskByUid({
  taskUid,
  onSuccess,
  onError,
}: fetchTaskByUidParams): Unsubscribable {
  return fetchFromAPIBase({
    path: `/v1/tasks/${taskUid}/`,
    method: "GET",
  }).subscribe((response) => {
    if (response && !response.error) {
      onSuccess(response)
    } else {
      onError(parseError(response))
    }
  })
}

interface TaskPayload {
  type: TaskType
  title: string
  descriptionMd: string
  taskListUid: string
  assigneeUid: string
}

interface createTaskParams {
  payload: TaskPayload
  onSuccess: (task: Task) => void
  onError: (error: EmberApiError) => void
}

export function createTask({
  payload,
  onSuccess,
  onError,
}: createTaskParams): Unsubscribable {
  return fetchFromAPIBase({
    path: "/v1/tasks/",
    method: "POST",
    body: payload,
  }).subscribe((response) => {
    if (response && !response.error) {
      onSuccess(response.task)
    } else {
      onError(parseError(response))
    }
  })
}

interface updateTaskParams {
  taskUid: string
  payload: TaskPayload
  onSuccess: (task: Task) => void
  onError: (error: EmberApiError) => void
}

export function updateTask({
  taskUid,
  payload,
  onSuccess,
  onError,
}: updateTaskParams): Unsubscribable {
  return fetchFromAPIBase({
    path: `/v1/tasks/${taskUid}/`,
    method: "PUT",
    body: payload,
  }).subscribe((response) => {
    if (response && !response.error) {
      onSuccess(response.task)
    } else {
      onError(parseError(response))
    }
  })
}

interface deleteTaskParams {
  taskUid: string
  onSuccess: () => void
  onError: (error: EmberApiError) => void
}

export function deleteTask({
  taskUid,
  onSuccess,
  onError,
}: deleteTaskParams): Unsubscribable {
  return fetchFromAPIBase({
    path: `/v1/tasks/${taskUid}/`,
    method: "DELETE",
  }).subscribe((response) => {
    if (response && !response.error) {
      onSuccess()
    } else {
      onError(parseError(response))
    }
  })
}

interface toggleTaskSubscriptionParams {
  taskUid: string
  subscribe: boolean
  onSuccess: () => void
  onError: (error: EmberApiError) => void
}

export function toggleTaskSubscription({
  taskUid,
  subscribe,
  onSuccess,
  onError,
}: toggleTaskSubscriptionParams): Unsubscribable {
  return fetchFromAPIBase({
    path: `/v1/tasks/${taskUid}/subscription/`,
    method: subscribe ? "PUT" : "DELETE",
  }).subscribe((response) => {
    if (response && !response.error) {
      onSuccess()
    } else {
      onError(parseError(response))
    }
  })
}
