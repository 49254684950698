// @ts-strict-ignore
import React, { useState } from "react"

import { Form, Formik } from "formik"
import * as Yup from "yup"

import { Alert, Button, Loading, Stack, Text } from "@kiwicom/orbit-components"

import { EmberApiError } from "api/errors"
import { createTask, updateTask } from "api/tasks"

import {
  EmberModal,
  EmberModalHeader,
  EmberModalSection,
} from "components/generic/ember-modal"
import { EmberSelectOption } from "components/generic/ember-select"
import {
  EmberFormSelect,
  EmberTextInput,
  EmberTextInputWithMentions,
} from "components/generic/formik-inputs"

import { MinimalProfile } from "types/person"
import { Task, TaskList, TaskStatus, TaskType } from "types/task"

import { getPersonName } from "utils/name-utils"
import { useGlobalFetcher } from "utils/state-utils"
import { snakeCaseToTitleCase } from "utils/string-utils"

export const taskStatusSelectOption = (
  status: TaskStatus
): EmberSelectOption => ({
  id: status ?? "",
  title: snakeCaseToTitleCase(status ?? ""),
})

const taskTypeSelectOption = (type: TaskType): EmberSelectOption => ({
  id: type ?? "",
  title: snakeCaseToTitleCase(type ?? ""),
})

const taskListSelectOption = (taskList: TaskList): EmberSelectOption => ({
  id: taskList.uid,
  title: taskList.name,
})

const assigneeSelectOption = (assignee: MinimalProfile): EmberSelectOption => ({
  id: assignee?.uid ?? "",
  title: assignee ? getPersonName(assignee) : "",
})

interface TaskModalProps {
  existingTask?: Task
  allTaskLists: TaskList[]
  defaultTaskList?: TaskList
  handleClose: () => void
  handleSuccess: (task: Task) => void
}

export const TaskModal = ({
  existingTask = null,
  allTaskLists,
  defaultTaskList = null,
  handleClose,
  handleSuccess,
}: TaskModalProps) => {
  const [error, setError] = useState<EmberApiError>(null)
  const preselectedTaskList = existingTask?.taskList ?? defaultTaskList
  const chatUsersFetcher = useGlobalFetcher("chatUsers")

  return (
    <EmberModal onClose={handleClose}>
      <EmberModalHeader title={existingTask ? "Edit Task" : "New Task"} />
      <EmberModalSection>
        <Formik
          initialValues={{
            status: taskStatusSelectOption(
              existingTask?.status ?? TaskStatus.TODO
            ),
            title: existingTask?.title ?? "",
            descriptionMd: existingTask?.descriptionMd ?? "",
            type: taskTypeSelectOption(existingTask?.type),
            taskList: taskListSelectOption(preselectedTaskList),
            assignee: assigneeSelectOption(existingTask?.assignee),
          }}
          validationSchema={Yup.object({
            title: Yup.string().required("The task needs a title"),
            type: Yup.object().test(
              "not-empty",
              "What type of task is this?",
              (value) => !!value.id
            ),
            taskList: Yup.object().test(
              "not-empty",
              "Which list should we add this to?",
              (value) => !!value.id
            ),
          })}
          validateOnBlur={
            /* This and `validateOnChange` needed to avoid the field going red as soon as you open the select drop-down */
            false
          }
          validateOnChange={false}
          onSubmit={(values, { setSubmitting }) => {
            setError(null)
            setSubmitting(true)
            const payload = {
              status: values.status.id as TaskStatus,
              type: values.type.id as TaskType,
              title: values.title,
              descriptionMd: values.descriptionMd,
              taskListUid: values.taskList?.id,
              assigneeUid: values.assignee.id,
            }
            const handlers = {
              onSuccess: handleSuccess,
              onError: (error: EmberApiError) => {
                setError(error)
                setSubmitting(false)
              },
            }
            if (existingTask) {
              updateTask({ taskUid: existingTask.uid, payload, ...handlers })
            } else {
              createTask({ payload, ...handlers })
            }
          }}
        >
          {({ isSubmitting }) => (
            <Form>
              <Stack>
                {existingTask && (
                  <EmberFormSelect
                    name="status"
                    label="Status"
                    options={Object.values(TaskStatus).map(
                      taskStatusSelectOption
                    )}
                  />
                )}
                <EmberTextInput name="title" label="Title" />
                <EmberTextInputWithMentions
                  name="descriptionMd"
                  label="Description"
                />
                <EmberFormSelect
                  name="type"
                  label="Task Type"
                  options={Object.values(TaskType).map(taskTypeSelectOption)}
                />
                <EmberFormSelect
                  name="taskList"
                  label="Task List"
                  options={allTaskLists.map(taskListSelectOption)}
                />
                {chatUsersFetcher.errorMessage ? (
                  <Alert type="warning">
                    <Text>
                      Failed to fetch a list of chat users:{" "}
                      {chatUsersFetcher.errorMessage}
                    </Text>
                  </Alert>
                ) : !chatUsersFetcher.data ? (
                  <Loading type="inlineLoader" text="Loading chat users..." />
                ) : (
                  <EmberFormSelect
                    name="assignee"
                    label="Assignee"
                    options={chatUsersFetcher.data.map(assigneeSelectOption)}
                  />
                )}
                <Button fullWidth submit loading={isSubmitting}>
                  {existingTask ? "Update" : "Create"}
                </Button>
                {error && (
                  <Alert type="critical">
                    <Text>{error.message}</Text>
                  </Alert>
                )}
              </Stack>
            </Form>
          )}
        </Formik>
      </EmberModalSection>
    </EmberModal>
  )
}
