import { Conversation } from "./chat"
import { MinimalProfile } from "./person"

export enum TaskType {
  MAINTENANCE = "maintenance",
  TRIP_JOB = "trip_job",
  CHARGER_JOB = "charger_job",
  STAFF_JOB = "staff_job",
  VEHICLE_JOB = "vehicle_job",
  WALKAROUND_CHECK = "walkaround_check",
  OTHER = "other",
}

export enum TaskStatus {
  TODO = "todo",
  IN_PROGRESS = "in_progress",
  COMPLETE = "complete",
}

export const taskStatusIsClosed = (status: TaskStatus) => {
  return status == TaskStatus.COMPLETE
}

export interface TaskList {
  uid: string
  name: string
}

/** Main data structure for describing tasks */
export interface Task {
  uid: string
  type: TaskType
  status: TaskStatus
  statusLastModifiedAt: string
  title: string
  descriptionMd: string
  descriptionRendered: string
  taskList?: TaskList
  assignee: MinimalProfile
  conversation: Conversation
  createdAt: string
  createdBy: MinimalProfile
  updatedAt?: string
  updatedBy?: MinimalProfile
  snoozedUntil: string
  deadline: string
  resolutionNote: string
}

/** `TaskSummary` is what the task search endpoint returns */
export interface TaskSummary {
  uid: string
  status: TaskStatus
  title: string
  assignee: MinimalProfile
  createdAt: string
}

/**
 *`TaskData` is what fetchTaskByUid returns: the full task data, plus a boolean flag indicating
 * whether the logged in user is subscribed to updates to the task
 */
export interface TaskInfo {
  task: Task
  editable: boolean
  deletable: boolean
  subscribed: boolean
}
