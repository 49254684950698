// @ts-strict-ignore
import React, { useEffect, useState } from "react"

import { Alert, Loading } from "@kiwicom/orbit-components"

import { EmberApiError } from "api/errors"
import { fetchTaskLists } from "api/tasks"

import Container from "components/container"

import { Task, TaskList, TaskSummary } from "types/task"

import { sortBy } from "utils/struct-utils"

import { TaskDetails } from "./details"
import { TaskModal } from "./modal"
import { TaskSelector } from "./selector"

interface TaskListState {
  allTaskLists: TaskList[]
  error: EmberApiError
}

const useTaskLists = (): TaskListState => {
  const [allTaskLists, setAllTaskLists] = useState<TaskList[]>(null)
  const [error, setError] = useState<EmberApiError>(null)
  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    if (allTaskLists == null && !loading) {
      setLoading(true)
      const sub = fetchTaskLists({
        onSuccess: (allTaskLists: TaskList[]) => {
          setLoading(false)
          if (allTaskLists.length == 0) {
            setError({ message: "No task lists were found in the system" })
          } else {
            setAllTaskLists(
              sortBy(allTaskLists, (taskList) => taskList.name.toLowerCase())
            )
          }
        },
        onError: (error: EmberApiError) => {
          setLoading(false)
          setError(error)
        },
      })
      return () => sub.unsubscribe()
    }
  }, [allTaskLists])

  return { allTaskLists, error }
}

interface LocationState {
  taskListUid?: string
  taskUid?: string
}

const useLocation = (): {
  location: LocationState
  updateLocation: (update: LocationState) => void
} => {
  const [location, setLocation] = useState<LocationState>({})

  const locationToHash = (): string =>
    location.taskUid
      ? `#task=${location.taskUid}`
      : location.taskListUid
      ? `#taskList=${location.taskListUid}`
      : ""

  const hashToLocation = (hash: string): LocationState => {
    const location = {} as LocationState
    if (hash) {
      const params = new URLSearchParams(hash.substring(1))
      if (params.has("taskList")) {
        location.taskListUid = params.get("taskList")
      }
      if (params.has("task")) {
        location.taskUid = params.get("task")
      }
    }
    return location
  }

  const updateLocationFromHash = () => {
    const hash = window.location.hash
    const newLocation = hashToLocation(hash)
    if (JSON.stringify(newLocation) != JSON.stringify(location)) {
      setLocation(newLocation)
    }
  }

  useEffect(updateLocationFromHash, [])
  useEffect(() => {
    window.addEventListener("hashchange", updateLocationFromHash)
    return () =>
      window.removeEventListener("hashchange", updateLocationFromHash)
  }, [])

  useEffect(() => {
    const newHash = locationToHash()
    if (newHash != window.location.hash) {
      window.location.hash = newHash
    }
  }, [JSON.stringify(location)])

  return {
    location,
    updateLocation: (update: LocationState) => {
      setLocation({
        ...location,
        ...update,
      })
    },
  }
}

const TasksPage = () => {
  const { allTaskLists, error } = useTaskLists()
  const { location, updateLocation } = useLocation()
  const [newTaskModalProps, setNewTaskModalProps] = useState<{
    existingTask?: Task
    defaultTaskList?: TaskList
  }>(null)
  const [refreshCount, setRefreshCount] = useState<number>(0)

  useEffect(() => {
    if (allTaskLists && allTaskLists.length > 0 && !location.taskListUid) {
      updateLocation({ taskListUid: allTaskLists[0].uid })
    }
  }, [allTaskLists, JSON.stringify(location)])

  if (error) {
    return (
      <Alert type="critical">Failed to fetch task lists: {error.message}</Alert>
    )
  }
  if (!allTaskLists) {
    return <Loading text="Loading task lists..." />
  }

  const selectedTaskList =
    location.taskListUid &&
    allTaskLists.find((taskList) => taskList.uid == location.taskListUid)

  return (
    <Container>
      {newTaskModalProps && (
        <TaskModal
          {...newTaskModalProps}
          allTaskLists={allTaskLists}
          handleClose={() => {
            setNewTaskModalProps(null)
          }}
          handleSuccess={(task: Task) => {
            // If we were already looking at a task, switch to the new one. Else, switch to the list
            // we just added it to
            if (location.taskUid) {
              updateLocation({ taskUid: task.uid })
            } else if (location.taskListUid != task.taskList?.uid) {
              updateLocation({ taskListUid: task.taskList.uid })
            }
            setRefreshCount(refreshCount + 1)
            setNewTaskModalProps(null)
          }}
        />
      )}
      {location.taskUid ? (
        <TaskDetails
          key={refreshCount}
          taskUid={location.taskUid}
          onTaskListClick={(taskList: TaskList) => {
            updateLocation({ taskListUid: taskList.uid, taskUid: null })
          }}
          onTaskEditClick={(task: Task) => {
            setNewTaskModalProps({ existingTask: task })
          }}
          onNewTaskClick={(defaultTaskList: TaskList) => {
            setNewTaskModalProps({ defaultTaskList })
          }}
          onTaskDeleted={(task: Task) => {
            updateLocation({ taskListUid: task.taskList?.uid, taskUid: null })
          }}
        />
      ) : selectedTaskList ? (
        <TaskSelector
          allTaskLists={allTaskLists}
          selectedTaskList={selectedTaskList}
          setSelectedTaskList={(taskList: TaskList) =>
            updateLocation({ taskListUid: taskList.uid })
          }
          setSelectedTask={(task: TaskSummary) =>
            updateLocation({ taskUid: task.uid })
          }
          refreshCount={refreshCount}
          onNewTaskClick={(defaultTaskList: TaskList) =>
            setNewTaskModalProps({ defaultTaskList })
          }
        />
      ) : (
        ""
      )}
    </Container>
  )
}

export default TasksPage
