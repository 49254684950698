// @ts-strict-ignore
import React, { useEffect, useState } from "react"

import styled, { css } from "styled-components"

import {
  Alert,
  Box,
  Loading,
  Select,
  Stack,
  Text,
  TextLink,
} from "@kiwicom/orbit-components"
import media from "@kiwicom/orbit-components/lib/utils/mediaQuery"

import { EmberApiError } from "api/errors"
import { TaskSelectionQuery, fetchTasks } from "api/tasks"

import { TaskList, TaskStatus, TaskSummary, TaskType } from "types/task"

import { snakeCaseToTitleCase } from "utils/string-utils"
import { sortBy } from "utils/struct-utils"

interface TaskState {
  allTasks: TaskSummary[]
  error: EmberApiError
}

const useTasks = ({
  query,
  refreshCount,
}: {
  query: TaskSelectionQuery
  refreshCount: number
}): TaskState => {
  const [allTasks, setAllTasks] = useState<TaskSummary[]>(null)
  const [error, setError] = useState<EmberApiError>(null)

  useEffect(() => {
    setAllTasks(null)
    const sub = fetchTasks({
      query,
      onSuccess: setAllTasks,
      onError: setError,
    })
    return () => sub.unsubscribe()
  }, [JSON.stringify(query), refreshCount])

  return { allTasks, error }
}

// This copied & adapted from https://github.com/kiwicom/orbit/blob/master/packages/orbit-components/src/Card/CardSection/components/SectionHeader.tsx
const TaskCardContainer = styled.div`
  ${({ theme }) => css`
    cursor: pointer;
    background: ${theme.orbit.paletteWhite};
    border: ${theme.orbit.borderWidthCard} ${theme.orbit.borderStyleCard}
      ${theme.orbit.borderColorCard};
    border-radius: ${theme.orbit.borderRadiusNormal};

    /* mobile screens: no margin
    margin: 0 -${parseInt(theme.orbit.spaceMedium, 10) + 1}px;
    padding: ${theme.orbit.spaceMedium};

    /* desktop screens: comfy margin */
    ${media.largeMobile(css`
      margin: 0;
      padding: ${theme.orbit.spaceLarge};
    `)}

    &:hover {
      background: ${theme.orbit.paletteWhiteHover};
    }
  `}
`

interface TaskSelectionQueryEditorProps {
  query: TaskSelectionQuery
  setQuery: (query: TaskSelectionQuery) => void
}

const TaskSelectionQueryEditor = ({
  query,
  setQuery,
}: TaskSelectionQueryEditorProps) => {
  const updateQuery = (update) => setQuery({ ...query, ...update })

  const Selector = ({ field, placeholder, values }) => (
    <Select
      value={query[field] || ""}
      placeholder={placeholder}
      options={values.map((value) => ({
        value,
        label: snakeCaseToTitleCase(value),
      }))}
      onChange={(event) =>
        updateQuery({ [field]: event.currentTarget.value || null })
      }
    />
  )

  return (
    <Stack direction="row" shrink>
      <Selector
        field="type"
        placeholder="All Types"
        values={Object.values(TaskType)}
      />
      <Selector
        field="status"
        placeholder="All Statuses"
        values={Object.values(TaskStatus)}
      />
    </Stack>
  )
}

interface TaskCardStackProps {
  query: TaskSelectionQuery
  setSelectedTask: (task: TaskSummary) => void
  refreshCount: number
}

const TaskCardStack = ({
  query,
  setSelectedTask,
  refreshCount,
}: TaskCardStackProps) => {
  const { allTasks, error } = useTasks({ query, refreshCount })

  if (error) {
    return <Alert type="critical">{error.message}</Alert>
  }
  if (!allTasks) {
    return <Loading text="Loading tasks..." />
  }

  if (allTasks.length == 0) {
    return (
      <Box padding="medium">
        <Text>
          {Object.keys(query).length == 0
            ? "This last list is empty."
            : "No tasks found. Try broadening your search criteria."}
        </Text>
      </Box>
    )
  }

  return (
    <Stack spacing="none">
      {allTasks.map((task: TaskSummary) => (
        <TaskCardContainer key={task.uid} onClick={() => setSelectedTask(task)}>
          <Text>{task.title}</Text>
        </TaskCardContainer>
      ))}
    </Stack>
  )
}

interface TaskSelectorProps {
  allTaskLists: TaskList[]
  selectedTaskList: TaskList
  setSelectedTaskList: (taskList: TaskList) => void
  setSelectedTask: (task: TaskSummary) => void
  refreshCount: number
  onNewTaskClick: (defaultTaskList: TaskList) => void
}

export const TaskSelector = ({
  allTaskLists,
  selectedTaskList,
  setSelectedTaskList,
  setSelectedTask,
  refreshCount,
  onNewTaskClick,
}: TaskSelectorProps) => {
  const [query, setQuery] = useState<TaskSelectionQuery>({
    taskListUid: selectedTaskList.uid,

    // 2023-09-11 - this used to be TaskStatus.IN_PROGRESS, it should now ideally be a list of all
    // non-closed statuses. See `taskStatusIsClosed`. Backend would need to be modified to accept
    // multiple statuses, like the issue endpoint does.
    status: TaskStatus.TODO,
  })

  useEffect(() => {
    setQuery({ ...query, taskListUid: selectedTaskList.uid })
  }, [selectedTaskList.uid])

  const sortedTaskLists = sortBy(allTaskLists, (taskList) =>
    taskList.name.toLowerCase()
  )

  return (
    <Stack spacing="XLarge">
      <Stack direction="row" justify="end">
        <TextLink onClick={() => onNewTaskClick(selectedTaskList)}>
          New Task
        </TextLink>
      </Stack>
      <Stack>
        <Select
          label="Task List"
          value={selectedTaskList.uid}
          options={sortedTaskLists.map((taskList) => ({
            value: taskList.uid,
            label: taskList.name,
          }))}
          onChange={(event) =>
            setSelectedTaskList(allTaskLists[event.currentTarget.selectedIndex])
          }
        />
        <TaskSelectionQueryEditor query={query} setQuery={setQuery} />
        <TaskCardStack
          query={query}
          setSelectedTask={setSelectedTask}
          refreshCount={refreshCount}
        />
      </Stack>
    </Stack>
  )
}
